<template>
  <portal>
    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-row">
        <span
          class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-email-send-outline</v-icon>
          <h1 class="darkGrey--text">Notifications</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Users
            </span>
          </v-tooltip>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <notification-signup :embedded="true"></notification-signup>
      </v-col>
    </v-row>
  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import NotificationSignup from '@/views/NotificationSignup.vue'

export default Vue.extend({
  name: 'UserOrganizations',
  components: {
    Portal,
    NotificationSignup
  },
  data: () => ({
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'My Notifications',
        disabled: true,
        href: ''
      }
    ]
  })
})
</script>
